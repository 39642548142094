import React from "react";

function App() {
  return (
    <div className="App">
      <header className="App-header">Hello from Render!</header>
    </div>
  );
}

export default App;
